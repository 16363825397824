import { mapGetters } from "vuex";
import moment from "moment";
import CountryService from "@/core/services/country.service";
import MessagesApiService from "@/core/services/api.service.messages";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "message-groups",
  data() {
    return {
      groups: [],
      participants: [],
      membersDialog: false,
      loading: false,
      countries: [],
      selectedCountry: null
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.getCountries();
    this.getGroups();
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.groups");
    },
    headers() {
      return [
        { text: "ID", align: "left", value: "id", width: "5%" },
        {
          text: "Group code",
          align: "left",
          value: "code",
          sortable: false,
          width: "10%"
        },
        {
          text: "Group formed",
          align: "left",
          value: "isFormed",
          sortable: true,
          width: "10%"
        },
        {
          text: "Group owner",
          align: "left",
          value: "ownerId",
          sortable: false,
          width: "25%"
        },
        {
          text: "Participants",
          align: "center",
          value: "participants.length",
          sortable: false,
          width: "3%"
        },
        {
          text: "Creation date",
          align: "left",
          value: "createdDate",
          sortable: false,
          width: "15%"
        },
        {
          text: "Formation date",
          align: "left",
          value: "formedDate",
          sortable: true,
          width: "15%"
        },
        {
          text: "Group members",
          align: "center",
          value: "participants",
          sortable: false,
          width: "3%"
        }
      ];
    }
  },
  methods: {
    getCountries() {
      this.loading = true;
      CountryService.getCountries()
        .then(res => {
          if (!res?.length) return;
          this.countries = res;
          const activeCountryId = CountryService.getActiveCountry();
          this.selectedCountry = this.countries.find(item => item.id === activeCountryId);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    onChangeCountry() {
      this.getGroups();
    },
    getGroups() {
      const params = {};
      // params["formed"] = "null";
      params["countryId"] = this.selectedCountry?.id || null;
      return MessagesApiService.getGroups(params)
        .then(response => {
          this.groups = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.loading = false;
          this.referrals = [];
        })
        .finally(() => (this.loading = false));
    },
    getOwnerData(ownerId) {
      const owner = this.groups.find(group => group.ownerId === ownerId);
      if (owner) {
        const ownerData = owner.participants.find(participant => participant.profileId === ownerId);
        if (ownerData) {
          return ` Name: <span style="color: rgba(58,57,57,0.91); font-weight: 600; text-align: center"> ${ownerData.username}</span>  <br>
           Email: ${ownerData.email} `;
        }
      }
      return "Owner not found";
    },
    /* getParticipantsData(participants) {
      if (participants && participants.length > 0) {
        return participants
          .map(
            participant =>
              `<span style="color: rgba(58,57,57,0.91); font-weight: 600;">${participant.username}</span> - ${participant.email} `
          )
          .join("<br>");
      }
      return "No participants";
    },*/
    showMembersDialog(members) {
      this.participants = members;
      members.membersDialog = true;
    },
    hideMembersDialog(members) {
      console.log(members);
      members.membersDialog = false;
    },
    formatDate(inputDate) {
      const date = moment(inputDate);
      if (date.isValid()) {
        return date.format("DD MMM YYYY | HH:mm");
      } else {
        return "Group not formed";
      }
    }
  }
};
